Util = {};
Util.getQueryHash = function () {
  let result = {};
  let query = document.location.search;
  query = decodeURIComponent(query);
  if (query.length < 1) return result;

  query = query.substring(1);
  const kvs = query.split('&');
  for (let i = 0; i < kvs.length; i++) {
    const element = kvs[i].split('=');
    const key = element[0];
    const value = element[1];
    if (value !== undefined) { result[key] = value; }
    else { result[key] = ''; }
  }

  return result;
};

Util.generateQueryString = function(hash){
  let result = '';
  for (let key in hash) {
    let value = hash[key];
    let element = key + '=' + value;
    if (result.length > 1) { element = '&' + element }
    result = result + element;
  }
  return result;
};

Util.setQueryHash = function (hash) {
  document.location.search = Util.generateQueryString(hash);
};

Util.controller = function () {
  return $('body').attr('data-controller');
};

Util.action = function () {
  return $('body').attr('data-action');
};

Util.controller_is = function (target) {
  return Util.controller() === target;
};

Util.action_is = function (target) {
  if (target instanceof Array) {
    let result = false;
    for (let i = 0; i < target.length; i++){
      result = (Util.action() === target[i]);
      if (result) break;
    }
    return result;
  }

  return Util.action() === target;
};
