$(function(){
  if (!(Util.controller_is('billings'))) {
    return false;
  }

  // inputのフォーマット
  $('.input-date').toArray().forEach(function(field){
    new Cleave( field , {
      date: true,
      delimiter: '/',
      datePattern: ['Y', 'm', 'd']
    });
  });

  const calcTotalPrices = function() {
    const $subtotalPriceSpan = $('.js-subtotal-price span');
    const $subtotalPriceInput = $('.js-subtotal-price input');
    const $taxPriceSpan = $('.js-tax-price span');
    const $taxPriceInput = $('.js-tax-price input');
    const $totalPriceSpan = $('.js-total-price span');
    const $totalPriceInput = $('.js-total-price input');

    let subtotalPrice = parseInt($('td.js-record-total-price-field span').data('value'));
    $('.js-record-total-price-field input').each(function(){
      if (!$(this).val()) {
        return true;
      }
      subtotalPrice += parseInt($(this).val());
    });

    subtotalPrice = Math.round(subtotalPrice);
    let taxPrice = Math.round(subtotalPrice * $('th.js-tax input').val());
    let totalPrice = subtotalPrice + taxPrice;

    $subtotalPriceSpan.text('¥' + subtotalPrice.toLocaleString());
    $subtotalPriceInput.val(subtotalPrice);
    $taxPriceSpan.text('¥' + taxPrice.toLocaleString());
    $taxPriceInput.val(taxPrice);
    $totalPriceSpan.text('¥' + totalPrice.toLocaleString());
    $totalPriceInput.val(totalPrice);
  };

  // 行の追加
  $('tr.js-other-billing-field.disable').find('input').prop('disabled', true);
  $('a.js-addField').on('click',function(e){
    e.preventDefault();

    if($('tr.js-other-billing-field').length > $('this').parents('table').data('max-record')) {
      return;
    }

    const $targetTr = $('tr.js-other-billing-field.disable:first');
    $targetTr.find('input').prop('disabled', false);
    $targetTr.removeClass('disable');
  });

  // 行の削除
  $('form.edit_user_project_report').on('click', '.js-removeField', function(e){
    e.preventDefault();
    const $targetTr = $(this).parents('tr');
    $targetTr.addClass('disable');
    $targetTr.find('input').val('');
    $targetTr.find('input').prop('disabled', true);
    $targetTr.find('td.js-record-total-price-field span').text('¥0');

    // 最後に移動
    $targetTr.insertAfter('tr.js-other-billing-field:last');

    calcTotalPrices();
  });

  // 請求書の行ごとの計算
  $('input.js-calc-record-total-price').change(function(){
    const $targetTr = $(this).parents('tr');
    const unitPrice = $targetTr.find('input.js-unit-price').val();
    const unitCount = $targetTr.find('input.js-unit-count').val();

    // 行
    const $recordTotalPriceSpan = $targetTr.find('.js-record-total-price-field span');
    const $recordTotalPriceInput = $targetTr.find('.js-record-total-price-field input');

    if(!unitPrice || !unitCount) {
      $recordTotalPriceSpan.text('¥0');
      $recordTotalPriceInput.val(0);
      calcTotalPrices();
      return;
    }

    const price = Math.round(unitPrice * unitCount);
    $recordTotalPriceSpan.text('¥' + price.toLocaleString());
    $recordTotalPriceInput.val(price);
    calcTotalPrices();
  });
});
