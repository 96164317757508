import moment from 'moment';
import 'moment-duration-format' // durationをformatで表せるプラグイン

$(function(){
  if (!(Util.controller_is('user_project_reports'))) {
    return false;
  }

  const calcAndSetTotalWorkTime = function() {
    const startMoment = moment();
    let targetMoment = moment();
    let totalDay = 0;

    $('input.js-work-time').each(function(){
      if (!$(this).val()) {
        return true;
      }
      let tmpMoment = moment(new Date(startMoment.format('YYYY-MM-DD') + ' ' + $(this).val()));
      targetMoment.add(tmpMoment.hour(), 'h').add(tmpMoment.minute(), 'm');
      totalDay += 1
    });

    const workPerMinutes = $('.js-work-per-minutes').data('work-per-minutes');
    const totalTimeArr = moment.duration(targetMoment.diff(startMoment), 'ms').format("hh:mm", { trim: false }).split(':');
    const totalTimeHour = parseInt(totalTimeArr[0]);
    let totalTimeMinute = parseInt(totalTimeArr[1]);
    totalTimeMinute = totalTimeMinute - (totalTimeMinute % workPerMinutes);
    totalTimeMinute =  ('00' + totalTimeMinute).slice(-2); // 0 padding

    $('td.js-total-work-time span').text(totalTimeHour + ':' + totalTimeMinute);
    $('td.js-total-work-time input').val(totalTimeHour + ':' + totalTimeMinute);
    $('td.js-total-work-day span').text(totalDay);
    $('td.js-total-work-day input').val(totalDay);
  };

  $('input.js-calc-time').change(function(){
    const $parent = $(this).parents('tr');
    const $workTime = $parent.find('input.js-work-time');
    const current_date = $parent.find('input.js-current-date').val();
    const start = $parent.find('input.js-start').val();
    const finish = $parent.find('input.js-finish').val();
    const breakTime = $parent.find('input.js-break-time').val();

    if (!start || !finish) {
      $workTime.val('');
      calcAndSetTotalWorkTime();
      return;
    }

    let startMoment = moment(new Date(current_date + ' ' + start));
    const finishMoment = moment(new Date(current_date + ' ' + finish));

    if (!startMoment.isValid() || !finishMoment.isValid()) {
      return;
    }

    if (breakTime) {
      const breakTimeMoment = moment(new Date(current_date + ' ' + breakTime));
      if (!breakTimeMoment.isValid()) {
        return;
      }
      startMoment = startMoment.add(breakTimeMoment.hour(), 'h').add(breakTimeMoment.minute(), 'm')
    }

    let diff = finishMoment.diff(startMoment);
    let total_time = moment.utc(diff).format('HH:mm');
    $workTime.val(total_time);
    calcAndSetTotalWorkTime();
  });

  $('.input-time').toArray().forEach(function(field){
    new Cleave(field, {
      time: true,
      timePattern: ['h', 'm']
      });
  });

  $('.js-addField').on('click',function(e){
    e.preventDefault();
    $('.js-addedField tbody').append(
      '<tr>'
      +'<td><input class="workReport__input"></td>'
      +'<td><input class="workReport__input"></td>'
      +'<td><input class="workReport__input"></td>'
      +'<td><input class="workReport__input"></td>'
      +'<td><input class="workReport__input"></td>'
      +'<td><input class="workReport__input"></td>'
      +'<td><a href="">削除</a></td>'
      +'</tr>');
  });

  $('form.js-update').submit(function(){
    const $errorMessage = $('.error_message');
    $errorMessage.hide();
    let isValid = true;
    $('input.input-time').each(function(){
      // 初期化
      $(this).css('border-color', '');

      let val = $(this).val();
      if(val.length === 0) {
        return true;
      }

      // 不正なフォーマットの場合はエラー
      if (!val.match(/^\d{2}:\d{2}$/g)) {
        isValid = false;
        $(this).css('border-color', 'red');
      }
    });

    if(isValid) {
      return true;
    }

    $errorMessage.show();
    return false;
  });
});
