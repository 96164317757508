import iziModal from 'izimodal/js/iziModal.js';
$.fn.iziModal = iziModal;

$(function(){
  if (
    !(Util.controller_is('members') && Util.action_is('new')) &&
    !(Util.controller_is('members') && Util.action_is('create'))
  ) {
    return false;
  }

  const $inputBirthday = $('.entryForm__inputs--birthday select');
  $inputBirthday.wrap('<div class="entryForm__selectContainer entryForm__selectContainer--third"><div class="entryForm__selectBox"></div></div>');

  const $form = $('form#new_member');
  $form.validate({
    errorPlacement: function(error, element) {
      error.insertAfter(
        $(element).parents('.entryForm__inputContainer, .entryForm__selectContainer, .entryForm__agreementContainer:first'));
    },
    focusInvalid: false,
    invalidHandler: function(form, validator) {
      if (!validator.numberOfInvalids()) {
        return;
      }
      $('html, body').animate({
        scrollTop: $(validator.errorList[0].element).offset().top
      }, 500);
    },
      errorClass: "entryForm__error",
      errorElement: "div"
  });
  $.extend($.validator.messages, {
    required: 'この項目は必須です。',
  });

  const $entryFormModal = $('.entryFormModal');
  $entryFormModal.iziModal({
    headerColor: '#23334B',
    closeButton: true,
    radius: 3,
    borderBottom: false,
    padding: 0
  });

  $('.js-open-modal').click(function(e){
    e.preventDefault();
    if(!$form.valid()) {
      return false;
    }

    [...Array(15)].map(function(_e, index) {
      const num = index + 1;
      const fieldName = 'field' + num;
      const $filed = $('input.' + fieldName + ',select.' + fieldName + ',textarea.' + fieldName);
      let val = $filed.val().replace(/\r?\n/g, '<br />');

      switch (num) {
        case 1:
          val = $('label[for="' + $filed.attr('id') + '"]').text();
          break;
        case 6:
          val = '';
          $filed.each(function(i, element){
            if(val) {
              val += '/';
            }
            val += $(element).val();
          });
          break;
        case 7:
        case 15:
          if(val) {
            val = $filed.find('option:selected').text();
          }
          break;
        case 8:
        case 12:
          val = '';
          $filed.each(function(i, element){
            if(!$(element).is(':checked')) {
              return true;
            }
            if(val) {
              val += '/';
            }
            val += $('label[for="' + $(element).attr('id') + '"]').text();
          });
      }

      if (!val) {
        val = '入力なし';
      }

      $('.entryFormModal__dd.' + fieldName).html(val);
    });

    $entryFormModal.iziModal('open');
  });

  $('.js-submit').click(function(){
    $form.submit();
  });
});
