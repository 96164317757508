$(function(){
  if ($('body').attr('id') !== 'mypage') {
    return false;
  }

  const $form = $('form');
  $form.validate({
    errorPlacement: function(error, element) {
      $(element).parents('.card__inputContainer').append(error);
    },
    focusInvalid: false,
    invalidHandler: function(form, validator) {
      if (!validator.numberOfInvalids()) {
        return;
      }
      $('html, body').animate({
        scrollTop: $(validator.errorList[0].element).offset().top - 70 // header分
      }, 500);
    },
    errorClass: "card__inputError",
    errorElement: "div"
  });

  $.extend($.validator.messages, {
    required: 'この項目は必須です。',
  });
});
