$(function(){
  if (
    !(Util.controller_is('members') && Util.action_is('edit')) &&
    !(Util.controller_is('members') && Util.action_is('update'))
  ) {
    return false;
  }

  $('select.js-birth-date').wrap('<div class="card__inputContainer card__inputContainer--birth"></div>');
});
