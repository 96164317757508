$(function(){
  $(".category__cards").each(function(){
    $(this).children(".category__card--third").each(function(i ,$elm){
      if( (i+1) % 3 == 0 ) {
        $(this).css({'margin-right': '0px'});
      }
    });
    $(this).children(".category__card--half").each(function(i ,$elm){
      if( (i+1) % 2 == 0 ) {
        $(this).css({'margin-right': '0px'});
      }
    });
  });


  $(".js-headerNav").on('click', function(){
    const id = $(this).attr('id');
    const $modal = $(".headerModal");
    const $link = $(".js-headerNav")
    if($(this).hasClass('active')){
      $link.removeClass('active');
      $modal.removeClass('active');
      $(".headerModal__lists").addClass('hidden');
    }else{
      $link.removeClass('active');
      $(this).addClass('active');
      $modal.addClass('active');
      $(".headerModal__lists").addClass('hidden');
      $(".headerModal__lists--"+id).removeClass("hidden");
    }
  });
});